<template>
    <div class="popupMoudule" >
        <el-dialog width="324px" :visible.sync="isPopupShow" center :close-on-click-modal="false" :show-close="isShowClose" @close="cancelCloseClick()">
            <div slot="title">
                <img :src="icon" alt="">
                <p class="popupMouduleTitle">
                    {{ title }}
                </p>
                <p v-if="!isHtml" class="popupMouduleContent">{{ content }}</p>
                <!-- <p v-if="isHtml" v-html="content" class="popupMouduleContent"></p> -->
                <p v-if="showWithHtml && subContent" v-html="subContent" class="popupMouduleContent popupMouduleSubtitle">
                    
                </p>
                <p v-if="isHtml && content" class="popupMouduleContent popupMouduleSubtitle">
                    {{ content }}
                </p>
                <p v-if="isHtml && subContent" class="popupMouduleContent">
                    {{ subContent }}
                </p>
            </div>
            
            <span slot="footer" class="btnOne" v-if="btnType == 1">
                <el-button type="primary" @click="confirmClick()">{{ $t(confirmTxt) }}</el-button>

            </span>
            <span slot="footer" class="btnTwo" v-if="btnType == 2">
                <el-button class="btnCancel" v-loading.fullscreen.lock="fullscreenLoading" @click="cancelClick()">{{ $t(cancelTxt) }}</el-button>
                <el-button class="btnConfirm" v-loading.fullscreen.lock="fullscreenLoading" @click="confirmClick()">{{ $t(confirmTxt)
                }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props:{
        isHtml:{
            default:false,
            type:Boolean,
        },
        showWithHtml:{
            default:false,
            type:Boolean,
        },
        icon:{
            default:'',
            type:String,
        },
        title:{
            default:'',
            type:String,
        },
        content:{
            default:'',
            type:String,
        },
        popupVisible:{
            default:false,
            type:Boolean,
        },
        btnType:{
            default:1,
            type:Number,
        },
        isShowClose:{
            default:false,
            type:Boolean,
        },
        confirmTxt:{
            default:"MyFollowers_confirm",
            type:String,
        },
        cancelTxt:{
            default:"MyFollowers_Cancel",
            type:String,
        },
        subContent:{
            default:"",
            type:String,
        },
        fullscreenLoading:{
            default:false,
            type:Boolean
        }
    },
    // props: ['icon', 'title', 'content', 'popupVisible', 'btnType', isHtml:{

    // }],
    computed: {

    },
    data() {
        return {
            isPopupShow: false
        }
    },
    watch: {
        popupVisible(newVal, oldVal) {//监听
            // console.log(newVal,oldVal)
            this.isPopupShow = newVal
        },
    },
    methods: {
        cancelClick() {
            this.isPopupShow = false
            this.$emit('cancelPopupTips', false)
        },
        confirmClick() {
            this.isPopupShow = false
            this.$emit('confirmPopupTips', false)
        },
        cancelCloseClick(){
            this.isPopupShow = false
            this.$emit('cancelClosePopupTips', false)
        }
    }
};
</script>
<style lang="less">
.popupMoudule {
    .el-dialog {
        padding: 30px;

        .el-dialog__body {
            display: none;
        }

        .el-dialog__header {
            text-align: center;
            margin-bottom: 6px;

            .popupMouduleTitle {
                font-family: 'PingFangSC-Medium';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                /* 正文 */
                color: #333333;
                margin: 6px auto;
            }

            .popupMouduleContent {
                font-family: 'PingFangSC-Regular';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height */
                text-align: center;
                /* 正文 */
                color: #333333;
                margin: 14px auto;
                word-break: break-word;

            }
            .popupMouduleSubtitle{
                font-size: 16px;
            }
        }

        .el-dialog__footer {
            padding: 6px 0;

            .btnOne {

                .el-button--primary,
                .el-button--primary:focus,
                .el-button--primary:hover {
                    background: #4D71FF;
                    border-radius: 4px;
                    border-color: #4D71FF;
                    min-width: 84px;
                }
            }

            .btnTwo {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .btnCancel {
                    background: #E5E5E5;
                    border-radius: 4px;
                    border-color: #E5E5E5;
                    min-width: 84px;
                    color: #333333;
                }

                .btnConfirm {
                    background: #4D71FF;
                    border-radius: 4px;
                    border-color: #4D71FF;
                    min-width: 84px;
                    color: #FFFFFF;
                }
            }
        }
        .el-dialog__headerbtn{
            top: 10px;
            right: 20px;
        }


    }
}
</style>