<template>
    <div class="MyRecord MyMessage">
        <!-- <p class="pageTop">
          <Language />
      </p> -->
        <div class="PageCommon">
            <div class="bscroll" ref="bscroll">
                <div class="bscroll-container">
                    <h3 class="pageTitle" v-if="!mobileFlag">
                        {{ $t('menu_message') }}
                    </h3>
                    <div class="mobileFilterDiv" v-if="mobileFlag">
                        <el-button class="mobileFilterBtn" @click="mobileSearchDrawer = true" type="text" size="small">
                            {{ $t("mobileFilterBtnTxt") }}
                            <svg-icon class="greenIcon" icon-class="MobileFilterBtn"></svg-icon>
                        </el-button>
                    </div>
                    <div class="tableSearch" v-if="!mobileFlag">
                        <el-form label-position="top" :model="tableSearchForm" ref="tableSearchForm">
                            <el-form-item :label="$t('menu_message_searchtitle')" prop="Title">
                                <el-input v-model="tableSearchForm.Title"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('MyFollowers_OperationTime')" prop="DateRange">
                                <el-date-picker v-model="tableSearchForm.DateRange" type="daterange" range-separator="—"
                                    value-format="yyyy-MM-dd" :start-placeholder="$t('MyFollowers_ChooseStartTime')"
                                    :end-placeholder="$t('MyFollowers_ChooseEndTime')">
                                </el-date-picker>
                                <svg-icon class="dateIcon" icon-class="TimeIcon"></svg-icon>
                            </el-form-item>
                            <el-form-item class="noLabel">
                                <el-button class="serchFormBtn" @click="getData(1, 10)">
                                    {{ $t('MyFollowers_Search') }}
                                    <svg-icon icon-class="SearchIcon"></svg-icon>
                                </el-button>

                            </el-form-item>
                            <el-form-item class="noLabel">


                                <el-button class="resetFormBtn" @click="reset()">
                                    {{ $t('MyFollowers_Reset') }}
                                    <svg-icon icon-class="ResetIcon"></svg-icon>

                                </el-button>
                            </el-form-item>

                        </el-form>
                    </div>
                    <div class="tableDiv" v-if="!mobileFlag">
                        <el-table :data="tableData" border style="width: 100%">
                            <el-table-column width="80" v-if="!mobileFlag" :fixed="tableData.length !== 0 ? true : false"
                                prop="sortNum" :label="$t('MyFollowers_Number')">
                            </el-table-column>
                            <el-table-column prop="createTime" width="150" :label="$t('message_time')">
                            </el-table-column>
                            <el-table-column prop="title" width="180" :label="$t('message_title')">
                                <template slot-scope="scope">
                                    <div>
                                        {{ scope.row.title }}
                                        <span class="messageNew" v-if="!scope.row.read">{{ $t('message_new') }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="content" :width="mobileFlag ? '280' : 'auto'"
                                :label="$t('message_content')">
                                <template slot-scope="scope">
                                    <div v-html="scope.row.content">

                                    </div>
                                </template>
                            </el-table-column>
                            <!-- <el-table-column width="120" :label="$t('MyFollowers_ResultAfterModification')">
                        <template slot-scope="scope">
                            <div>
                                <el-result v-if="scope.row.result" icon="success"></el-result>
                                <el-result v-if="!scope.row.result" icon="error"></el-result>
                            </div>
                        </template>
                    </el-table-column> -->
                            <el-table-column :fixed="tableData.length !== 0 ? 'right' : false" v-if="!mobileFlag"
                                width="135" :label="$t('MyFollowers_Operation')">
                                <template slot-scope="scope">
                                    <!-- {{ scope.row.insTime | dateFilter }} -->
                                    <div class="operateBtns">
                                        <el-button @click="checkMessage(scope.row)" type="text" size="small"
                                            v-loading.fullscreen.lock="fullscreenLoading">
                                            <svg-icon class="greenIcon" icon-class="CheckMessage"></svg-icon>
                                            {{ $t("message_checkBtn") }}
                                        </el-button>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination class="tablePage" :prev-text="$t('MyFollowers_PreviousPage')" :pager-count="5"
                            :next-text="$t('MyFollowers_NexPage')" :current-page="currentPage"
                            @current-change="handleCurrentChange" @size-change="handleSizeChange"
                            :page-sizes="[5, 10, 20, 50, 100]" :page-size="pageSize" layout="prev, pager, next, sizes"
                            :total="total">
                        </el-pagination>
                    </div>

                    <div class="mobileTableList" v-show="mobileFlag">

                        <el-collapse accordion>
                            <el-collapse-item v-for="(item, index) in tableData" :key="index">
                                <template slot="title">
                                    <div class="mobileTableCard" @click.stop="showCollapse()">

                                        <div class="mobileTableItem">
                                            <p class="mobileTableTitle">
                                                {{ $t('message_time') }}
                                            </p>
                                            <p class="mobileTableContent">
                                                {{ item.createTime }}
                                            </p>
                                        </div>
                                        <div class="mobileTableItem">
                                            <p class="mobileTableTitle">
                                                {{ $t('message_title') }}
                                            </p>
                                            <p class="mobileTableContent">
                                                {{ item.title }}
                                                <span class="messageNew" v-if="!item.read">{{ $t('message_new') }}</span>

                                            </p>
                                        </div>
                                        <div class="mobileTableItem mobileTableStatusTitle">
                                            <p class="mobileTableTitle">
                                                {{ $t('message_content') }}
                                            </p>
                                            <p class="mobileTableContent">
                                            <div v-html="item.content">

                                            </div>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="mobileTableBtns" @click.stop="showCollapse()">
                                            
                                            <el-button class="mobileBlueBtn" @click="checkMessage(item)" type="text" size="small"
                                                v-loading.fullscreen.lock="fullscreenLoading">
                                                <svg-icon class="greenIcon" icon-class="CheckMessage"></svg-icon>
                                                {{ $t("message_checkBtn") }}
                                            </el-button>
                                    </div>

                                </template>
                               
                            </el-collapse-item>

                        </el-collapse>
                        <div class="x" v-if="up && tableData.length < total">
                            <i class="el-icon-loading "></i>
                            {{ $t('loading') }}
                        </div>
                        <div class="x" v-if="!up && tableData.length >= total">

                            {{ $t('noMoreData') }}
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <el-drawer class="mobileSearchDrawer" :visible.sync="mobileSearchDrawer" direction="ttb">
                <div class="mobileFilterReturnBtnDiv">
                    <el-button class="mobileFilterReturnBtn" @click="mobileSearchDrawer = false">
                        {{ $t('MyFollowers_Search') }}
                        <i class="el-icon-arrow-up"></i>
                    </el-button>
                </div>

                <el-form label-position="top" :model="tableSearchForm" ref="tableSearchForm">
                    <el-form-item :label="$t('menu_message_searchtitle')" prop="Title">
                        <el-input v-model="tableSearchForm.Title"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('MyFollowers_OperationTime')" prop="DateRange">
                        <el-date-picker v-model="tableSearchForm.DateRange" type="daterange" range-separator="—"
                            value-format="yyyy-MM-dd" :start-placeholder="$t('MyFollowers_ChooseStartTime')"
                            :end-placeholder="$t('MyFollowers_ChooseEndTime')">
                        </el-date-picker>
                        <svg-icon class="dateIcon" icon-class="TimeIcon"></svg-icon>
                    </el-form-item>
                </el-form>
                <div class="mobileFilterBottomBtns">
                    <el-button class="resetFormBtn" @click="reset()">
                        {{ $t('MyFollowers_Reset') }}
                    </el-button>
                    <el-button class="serchFormBtn" @click="getData(1, pageSize)">
                        {{ $t('MyFollowers_Search') }}
                    </el-button>
                </div>
            </el-drawer>
        <PopupTips :popupVisible='isPopupVisible' :icon="iconImgCheck" :title="popupTitle" :isShowClose="true"
            :showWithHtml="true" @cancelClosePopupTips="cancelPopup" ref="popupTipsRef" :subContent="$t(popupContent)"
            :btnType="0" />
    </div>
</template>

<script>
// @ is an alias to /src
import Language from "../../components/common/Language.vue";
import { concatParams, concatTableData, dateGmt8 } from "../../plugins/common.js";
import { mapState } from "vuex";
import PopupTips from '@/components/moudule/PopupTips.vue'
import BScroll from '@better-scroll/core'
  import Pullup from '@better-scroll/pull-up'
  
  BScroll.use(Pullup)

export default {
    name: 'MyRecord',
    components: {
        Language,
        PopupTips
    },
    computed: {
        ...mapState(["checkPortfolio", "mobileFlag"]),
    },
    data() {
        return {
            tableData: [],
            currentPage: 1,
            total: 0,
            pageSize: 5,
            tableSearchForm: {
                Title: '',
                DateRange: []
            },
            fullscreenLoading: false,
            isPopupVisible: false,
            isPopupVisible: false,
            iconImgCheck: '',
            iconImgError: require('../../assets/images/NotifyError.svg'),
            iconImgWarn: require('../../assets/images/NotifyWarning.svg'),
            iconImgSuccess: require('../../assets/images/NotifySuccess.svg'),
            popupContent: '',
            popupTitle: '',
            mobileSearchDrawer:false,
            up:false
        }
    },
    created() {
        this.getData(1, 5)
    },
    methods: {
        showCollapse(){},
        scrollFn(){
            this.$nextTick(() => {
            
                if (!this.scroll) {
                    this.scroll = new BScroll(this.$refs.bscroll, {
                        click: true,
                        scrollY: true,
                        probeType: 3
                    });
                }else{
                    this.scroll.refresh();
                }
                
                //touchEnd（手指离开以后触发） 通过这个方法来监听下拉刷新
                this.scroll.on('touchEnd', (pos) => {
                    //上拉加载 总高度>下拉的高度+10 触发加载更多
                    if(this.scroll.maxScrollY>pos.y + 50){
                        // console.log("加载更多")
                        this.up=true;
                        setTimeout(()=>{
                        //使用refresh 方法 来更新scroll  解决无法滚动的问题
                            if(this.tableData.length < this.total){
                                this.currentPage = this.currentPage + 1
                                this.getData(this.currentPage,this.pageSize,'noRefresh')
                                this.scroll.refresh();
                                this.up=false;
                            }else{
                                this.up=false;
                            }
                            
                    },1000) 
                    
                    }
                    // console.log(this.scroll.maxScrollY+"总距离----下拉的距离"+pos.y)
                })
            
                // console.log(this.scroll.maxScrollY)
            });
        },
        getData(pageIndex, pageSize,tag) {
            if(!this.mobileFlag || tag !== 'noRefresh'){
                this.tableData = [];

            }
            // console.log(this.tableSearchForm.length)
            var params = {
                Title: this.tableSearchForm.Title,
                DateRange: this.tableSearchForm.DateRange.length !== 0 ? this.tableSearchForm.DateRange[0] + '~' + this.tableSearchForm.DateRange[1] : '',
                pageIndex: pageIndex,
                pageSize: pageSize
            }
            this.$axios.get('/Api/home/MessageList' + concatParams(params)).then((res) => {
                let dt = res.data
                if (dt.isSuccess) {
                    // dt.result.pageList.forEach((item,index) =>{
                    //     item.createTime = dateGmt8(item.createTime)
                    // })
                    this.total = dt.result.total
                    // this.tableData = concatTableData(dt.result.pageList, dt.result.total, dt.result.pageIndex, this.pageSize)
                    if(this.mobileFlag && tag == 'noRefresh'){
                        dt.result.pageList.forEach((item) =>{
                            this.tableData.push(item)
                        })
                        
                    }else{
                        this.tableData = concatTableData(dt.result.pageList, dt.result.total, dt.result.pageIndex, this.pageSize)

                    }
                    
                    if(this.mobileFlag){
                        this.scrollFn()
                    }
                
                }
            }).finally(()=>{
                this.mobileSearchDrawer = false
            })
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getData(this.currentPage, this.pageSize)
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.getData(this.currentPage, this.pageSize);
        },
        reset() {
            this.$refs.tableSearchForm.resetFields();
        },
        cancelPopup(val) {
            this.isPopupVisible = val
            this.getData(this.currentPage, this.pageSize)
        },
        checkMessage(row) {
            this.iconImgCheck = ''
            switch (row.iconLevel) {
                case 1:
                    this.iconImgCheck = this.iconImgSuccess
                    break;
                case 2:
                    this.iconImgCheck = this.iconImgError
                    break;
                case 3:
                    this.iconImgCheck = this.iconImgWarn
                    break;
                default:
                    break;
            }
            this.popupTitle = row.title
            var data = row.content.split("<br/>")
            var dataW = []
            data.forEach(item =>{
                dataW.push(item.split("："))
            })
            if(data.length > 1){
                var con = ''
                dataW.forEach((item,index) =>{
                    console.log(dataW.length,index)
                    if(dataW.length-1 !== index &&  index !== 0){
                        con += '<div class="popSubDiv"><b>' + item[0] +':</b>'+ '<span>'+ item[1] +'</span></div>' 
                    }
                })
                // console.log(con)
                this.popupContent ='<div>'+ dataW[0][0]+': </div>' + con + '<div>'+ dataW[dataW.length-1][0]+'</div>'

            }else{
                this.popupContent = row.content
            }
            
            this.isPopupVisible = true
            var params = {
                id: row.id
            }
            this.$axios.get('/Api/home/MessageRead' + concatParams(params)).then((res) => {
                let dt = res.data
                if (dt.isSuccess) {

                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
.refreshListBtnDiv {
    position: absolute;
    right: 60px;
    top: 135px;
}

.operateBtns {
    padding-left: 0;
}

.messageNew {
    // display: flex;
    padding: 2px 4px;
    font-size: 12px;
    border-radius: 4px;
    background: #47A92A;
    color: #ffffff;
}

@media screen and (max-width:1685px) {
    .refreshListBtnDiv {
        top: 140px;
    }
}

@media screen and (max-width:1445px) {
    .refreshListBtnDiv {
        top: 148px;
    }
}

@media screen and (max-width:1285px) {
    .refreshListBtnDiv {
        top: 160px;
    }
}

@media screen and (max-width:1025px) {

    .refreshListBtnDiv {
        top: 40px;
    }

}

@media screen and (max-width: 767px) {
    .followAccount {
        .pageTitleBtn {
            margin: 20px 0 0 0;
        }
    }

    .home {
        .content {
            .tableSearch {
                .el-form {
                    .el-form-item {
                        width: 90%;
                    }

                    .el-date-editor--daterange.el-input__inner {
                        width: 100%;
                    }

                    .noLabel {
                        width: 48%;
                    }
                }
            }
        }
    }

    .refreshListBtnDiv {
        position: absolute;
        right: 20px;
        top: 5px;

        .confirmDialogBtn {
            padding: 10px;
            font-size: 12px;
            line-height: 15px;
        }
    }
}
</style>
